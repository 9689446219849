import { Theme } from "@mui/material/styles"
import {
    BORDER_RADIUS,
    BORDER_RADIUS_SMALL,
    FONT_SIZE_BODY_MAIN_HEADLINE,
    FONT_SIZE_BODY_SMALL,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    FONT_WEIGHT_SEMI_BOLD,
} from "styles/theme/constants"

export default function componentStyleOverrides(theme: Theme) {
    const mode = theme.palette.mode
    const BOX_SHADOW_FLOATING_TINY =
        "0px 0px 0px 1px rgb(0 0 0 / 0.04)," +
        " 0px 1px 1px -0.5px rgb(0 0 0 / 0.03)," +
        " 0px 2px 2px -1px rgb(0 0 0 / 0.03), " +
        " 0px 4px 4px -2px rgb(0 0 0 / 0.02)"

    const BOX_SHADOW_FLOATING_SMALL =
        "0px 2px 2px -1px rgb(0 0 0 / 6%)," +
        " 0px 4px 6px -2px rgb(0 0 0 / 5%)," +
        " 0px 6px 10px -3px rgb(0 0 0 / 4%)"

    const BOX_SHADOW_FLOATING_MEDIUM =
        "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)"

    const formFieldBgColor = mode === "dark" ? "black" : theme.palette.grey[50]
    const formFieldBgColorDisabled = theme.palette.grey[200]
    const formFieldBgColorHover = theme.palette.grey[400]

    const formFieldBorderColor = mode === "dark" ? theme.palette.text.primary + 28 : theme.palette.grey[300]

    // Please sort properties alphabetically
    return {
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    borderRadius: BORDER_RADIUS,
                    boxShadow: BOX_SHADOW_FLOATING_MEDIUM,
                    '& .MuiAutocomplete-option.Mui-focused, .MuiAutocomplete-option[aria-selected="true"], .Mui-focused ':
                        {
                            backgroundColor:
                                mode === "dark"
                                    ? theme.palette.primary.main + "!important"
                                    : theme.palette.primaryShades[100] + "!important",
                        },
                    "& .MuiAutocomplete-option": {
                        fontSize: "12px",
                        lineHeight: "1.3",
                        padding: "5px 16px",
                        margin: "1px 0",
                    },
                    "& .MuiPaper-root": {
                        "& .MuiAutocomplete-listbox": {
                            "& li": {
                                wordBreak: "break-all",
                                fontSize: "12px",
                                lineHeight: "1.3",
                                padding: "5px 16px",
                                margin: "1px 0",
                            },
                        },
                    },
                    // Add padding for multiple selection autocomplete popper
                    "&[data-multiple='true'] .MuiPaper-root": {
                        padding: "0 8px",
                    },
                },
                root: {
                    alignItems: "center",
                    "& .MuiAutocomplete-tag": {
                        borderRadius: BORDER_RADIUS,
                        minHeight: "24px",
                        padding: "5px 4px 5px 6px",
                        fontSize: "12px",
                        // Add styles for multi-select tags
                        maxWidth: "100%",
                        overflow: "hidden",
                        margin: "3px",
                        backgroundColor: "white",
                        height: "auto",
                        wordBreak: "break-word",
                        boxShadow: `0px 0px 0px 1px rgb(0 0 0 / 0.04),
                          0px 1px 1px -0.5px rgb(0 0 0 / 0.03),
                          0px 2px 2px -1px rgb(0 0 0 / 0.03),
                          0px 4px 4px -2px rgb(0 0 0 / 0.02)`,
                    },
                    "& .MuiChip-root": {
                        display: "flex",
                        alignItems: "flex-start",
                        height: "auto",
                        maxHeight: "55px",
                    },
                    "& .MuiChip-deleteIcon": {
                        fontSize: "14px",
                        margin: "0px 2px!important",
                        alignSelf: "flex-start",
                    },
                    "& .MuiChip-label": {
                        padding: "0 0 0 2px",
                        fontSize: "12px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        display: "inline-block",
                        lineHeight: "1.3",
                        maxWidth: "100%",
                        minHeight: "18px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: formFieldBorderColor,
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                        position: "absolute",
                        right: "3px",
                        top: "-15px",
                        paddingBottom: 0,
                        zIndex: 3,
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                        zIndex: 2,
                        backgroundColor: formFieldBgColor,
                        padding: 0,
                        marginRight: "1px",
                    },
                    "& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root": {
                        zIndex: 2,
                        backgroundColor: formFieldBgColor,
                        borderRadius: "50%",
                        height: "14px",
                    },
                    "& .MuiInputBase-root": {
                        minHeight: "40px",
                        paddingRight: "20px!important",
                    },
                    "& .MuiAutocomplete-endAdornment": {
                        backgroundColor: formFieldBgColor,
                        right: "1px!important",
                        "& .MuiIconButton-root": {
                            width: "20px",
                        },
                    },
                    // Add improved styling for multi-select fields
                    "&.MuiAutocomplete-root[multiple]": {
                        "& .MuiOutlinedInput-root": {
                            flexWrap: "wrap",
                            padding: "8px 4px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                            flexWrap: "wrap",
                        },
                    },

                    // ------- small variant ------------------------------------------------
                    // small components should be very compact, especially on small screens
                    "& .MuiInputBase-sizeSmall .MuiAutocomplete-clearIndicator": {
                        top: "-7px",
                    },
                    "& .MuiInputLabel-sizeSmall.MuiInputLabel-root": {
                        paddingRight: "0px!important",
                    },
                    "& .MuiInputLabel-sizeSmall.MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
                        // [theme.breakpoints.down("lg")]: {
                        //     left: "-8px",
                        //     top: "-2px",
                        // },
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    "&.badge-border .MuiBadge-badge": {
                        fontSize: "10px",
                        border: "1px solid white",
                        minWidth: "24px",
                    },
                },
                badge: {
                    "&.MuiBadge-colorSecondary": {
                        backgroundColor: theme.palette.grey[400],
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: FONT_WEIGHT_MEDIUM,
                    borderRadius: BORDER_RADIUS,
                    boxShadow: "none",
                    fontSize: FONT_SIZE_BODY_SMALL,
                    padding: "8px 16px",
                    minHeight: "42px",
                },
                sizeSmall: {
                    padding: "6px 12px",
                    minHeight: "36px",
                },
                sizeMedium: {
                    padding: "8px 16px",
                    minHeight: "42px",
                },
                sizeLarge: {
                    padding: "10px 20px",
                    minHeight: "48px",
                },
            },
            variants: [
                {
                    props: { variant: "contained", color: "secondary" } as const,
                    style: ({ theme }) => ({
                        backgroundColor: "#e1e7fa",
                        color: "#3562e3",
                        "&:hover": {
                            backgroundColor: "#b5c3f2", // TODO: Better color?
                        },
                    }),
                },
            ],
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: "12px",
                    borderWidth: "0",
                    "& .MuiDataGrid-columnsContainer": {
                        backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
                    },
                    "& .MuiDataGrid-withBorderColor": {
                        borderColor: theme.palette.border.subtle,
                    },
                    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                        borderRight: 0,
                        borderColor: theme.palette.border.subtle,
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            alignItems: "center",
                            "&  .MuiIconButton-root ": {
                                paddingTop: 0,
                            },
                            "& .MuiDataGrid-iconButtonContainer ": {
                                alignSelf: "start",
                                "& .MuiIconButton-root": {
                                    marginTop: "3px",
                                    padding: 0,
                                },
                            },
                            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                                flexDirection: "column",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                height: "30px",
                                whiteSpace: "normal",
                                lineHeight: "normal",
                            },
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            visibility: "visible",
                            "& svg": {
                                opacity: 0,
                            },
                            "&::before": {
                                transition: "opacity 0.2s ease-in-out",
                                opacity: 0,
                                content: '" "',
                                background: theme.palette.border.subtle,
                                borderRadius: "2px",
                                width: "2px",
                                height: "100%",
                                position: "absolute",
                                left: "50%",
                            },
                        },
                        "&:hover .MuiDataGrid-columnSeparator::before": {
                            opacity: 1,
                        },
                        "& .MuiDataGrid-menuIcon": {
                            height: "18px",
                            width: "18px",
                            padding: 0,
                            top: 0,
                            right: "5px",
                            marginRight: 0,
                            position: "absolute",
                            "& .MuiDataGrid-menuIconButton": {
                                padding: 0,
                            },
                        },
                        "& .MuiDataGrid-pinnedColumnHeaders .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnSeparator::before":
                            {
                                left: "10px",
                            },
                        ".MuiDataGrid-columnHeader:focus": {
                            outline: 0,
                        },
                    },

                    "& .MuiDataGrid-detailPanel": {
                        marginLeft: "8px",
                        border: "1px solid #f0f0f0",
                        borderBottom: 0,
                        "& .MuiTableCell-head": {
                            fontSize: "13px",
                        },
                        "& .MuiTableCell-root": {
                            fontSize: "12px",
                        },
                    },
                    "& .MuiDataGrid-pinnedColumns": {
                        boxShadow: "none",
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            boxShadow: "none",
                        },
                    },
                    "&:hover .MuiDataGrid-columnHeader--pinnedLeft, &:hover .MuiDataGrid-cell--pinnedLeft": {
                        boxShadow: "2px 0px 4px -2px rgba(0, 0, 0, 0.21)",
                    },
                    "&:hover .MuiDataGrid-columnHeader--pinnedRight, &:hover .MuiDataGrid-cell--pinnedRight": {
                        boxShadow: "-2px 0px 4px -2px rgba(0, 0, 0, 0.21)",
                    },
                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: FONT_WEIGHT_SEMI_BOLD,
                    },
                    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
                        borderColor: theme.palette.border.subtle,
                    },
                    "& .MuiDataGrid-cell": {},
                    "& .MuiPaginationItem-root": {
                        borderRadius: 0,
                    },
                    "& .MuiDataGrid-checkboxInput": {
                        padding: "3px",
                        "& .MuiSvgIcon-root": {
                            height: "18px",
                            width: "18px",
                        },
                    },

                    "& .MuiDataGrid-row": {
                        "& .MuiDataGrid-cell": {
                            // [theme.breakpoints.down("lg")]: {
                            // fontSize: FONT_SIZE_BODY_SMALL,
                            // minHeight: '30px!important',
                            // },
                        },
                        "& .MuiDataGrid-cell:has(*)": {
                            overflow: "hidden",
                            alignItems: "center",
                            display: "flex",
                        },
                    },
                },
            },
        },

        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiToolbar-root": {
                        minHeight: "30px",
                    },
                    "& .MuiInputBase-input-root": {
                        fontSize: FONT_SIZE_BODY_SMALL,
                    },
                    "& p": {
                        margin: 0,
                        padding: 0,
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    "& .custom-dialog-title": {
                        paddingBottom: 0,
                    },
                    "& .MuiTabs-flexContainer": {
                        borderBottom: "none",
                    },
                    "& .MuiTabs-scroller": {
                        marginLeft: "16px",
                        // [theme.breakpoints.down("lg")]: {
                        //     marginLeft: "14px",
                        // },
                    },
                    "& .MuiTabPanel-root": {
                        padding: "8px 20px",
                    },
                    "& .layout-mode-tabs .element-header": {
                        marginLeft: "12px",
                    },
                    "& .MuiDialogTitle-root": {
                        paddingLeft: theme.spacing(4),
                        paddingRight: theme.spacing(4),
                        fontSize: "24px",
                        "& *": {
                            fontSize: "24px",
                        },
                    },
                    "& .MuiDialogContent-root": {
                        padding: 0,
                        paddingBottom: theme.spacing(2),
                        "& .MuiTabPanel-root": {
                            paddingTop: theme.spacing(2),
                        },
                    },
                    "& .MuiDialogContentText-root": {
                        color: theme.palette.text.primary,
                    },
                    "& .MuiDialogContentText-root, & .generic-form:not(.layout-mode-tabs)": {
                        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
                    },
                    "& .MuiDialogActions-root": {
                        padding: theme.spacing(4),
                        paddingBottom: theme.spacing(4),
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.palette.divider,
                    opacity: mode === "dark" ? 0.2 : 1,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginBottom: theme.spacing(2),
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.MuiInputLabel-shrink": {
                        color: theme.palette.text.primary,
                        fontWeight: FONT_WEIGHT_SEMI_BOLD,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: BORDER_RADIUS,
                    fontSize: FONT_SIZE_BODY_SMALL,
                    // [theme.breakpoints.down('lg')]: {
                    //     fontSize: FONT_SIZE_BODY_SMALL,
                    // },
                    padding: theme.spacing(1),
                    // '& svg': {
                    //     marginRight: theme.spacing(1)
                    // },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    // ------- small variant ------------------------------------------------
                    // small components should be very compact, especially on small screens
                    "&.MuiInputBase-sizeSmall.MuiInputBase-root": {
                        // [theme.breakpoints.down("lg")]: {
                        //     padding: "0!important",
                        //     minHeight: "30px!important",
                        //     "&.MuiInputBase-adornedStart": {
                        //         paddingLeft: "4px!important",
                        //     },
                        // },
                    },
                    "&.MuiInputBase-sizeSmall input": {
                        paddingBottom: "11px",
                        paddingTop: "11px",
                        // [theme.breakpoints.down("lg")]: {
                        //     padding: "0 0 0 2px",
                        // },
                    },
                    "&.MuiInputBase-sizeSmall .MuiIconButton-root": {
                        padding: "0 2px",
                        "& svg": {
                            margin: "0 3px",
                        },
                        // [theme.breakpoints.down("lg")]: {
                        //     padding: 0,
                        //     "& svg": {
                        //         margin: "0 2px",
                        //     },
                        // },
                    },
                    "&.MuiInputBase-sizeSmall .MuiAutocomplete-endAdornment .MuiIconButton-root": {
                        "& svg": {
                            margin: "0",
                        },
                    },
                },
                input: {
                    color: theme.palette.text.primary,
                    "&::placeholder": {
                        color: theme.palette.text.secondary,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "& .MuiSvgIcon-root": {
                        marginRight: theme.spacing(1),
                        height: "16px",
                        width: "16px",
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: BOX_SHADOW_FLOATING_MEDIUM,
                    borderRadius: BORDER_RADIUS,
                },
                list: {
                    padding: theme.spacing(1),
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: formFieldBgColor,
                    borderRadius: BORDER_RADIUS,
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: formFieldBorderColor,
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: formFieldBgColorDisabled,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: formFieldBgColorHover,
                    },
                    "&:hover.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main,
                    },
                    "&:hover.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: formFieldBgColorDisabled,
                    },
                    "&.MuiInputBase-multiline": {
                        padding: 1,
                    },
                },
                input: {
                    fontWeight: 500,
                    background: formFieldBgColor,
                    padding: "15.5px 14px",
                    borderRadius: BORDER_RADIUS,
                    "&.MuiInputBase-inputSizeSmall": {
                        padding: "10px 14px",
                        "&.MuiInputBase-inputAdornedStart": {
                            paddingLeft: 0,
                        },
                    },
                },
                inputAdornedStart: {
                    paddingLeft: 4,
                },
                notchedOutline: {
                    borderRadius: BORDER_RADIUS,
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                    "&.MuiDialog-paperScrollPaper": {
                        // [theme.breakpoints.down("lg")]: {
                        //     margin: theme.spacing(1),
                        // },
                    },
                    "&[role='menu']": {
                        // Target paper elements that are menus
                        boxShadow: BOX_SHADOW_FLOATING_MEDIUM,
                        borderRadius: BORDER_RADIUS,
                    },
                },
                rounded: {
                    borderRadius: BORDER_RADIUS,
                },
            },
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    "&[role='menu']": {
                        // This targets all poppers that serve as menus
                        "& .MuiPaper-root": {
                            boxShadow: BOX_SHADOW_FLOATING_MEDIUM,
                            borderRadius: BORDER_RADIUS,
                        },
                    },
                    "&.MuiDataGrid-menu": {
                        // Specifically target DataGrid menu poppers
                        "& .MuiPaper-root": {
                            boxShadow: BOX_SHADOW_FLOATING_SMALL,
                            borderRadius: BORDER_RADIUS,
                        },
                    },
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: BOX_SHADOW_FLOATING_MEDIUM,
                    borderRadius: BORDER_RADIUS,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: mode === "dark" ? theme.palette.text.primary + 15 : theme.palette.grey[200],
                    "&.MuiTableCell-head": {
                        fontSize: "0.875rem",
                        color: theme.palette.grey[600],
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    margin: "0",
                    border: "1px solid " + (mode === "dark" ? theme.palette.text.primary + 20 : theme.palette.divider),
                    borderRadius: BORDER_RADIUS_SMALL,
                    padding: theme.spacing(1),
                    background: theme.palette.background.paper,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    // [theme.breakpoints.down("lg")]: {
                    //     minHeight: "32px",
                    // },
                    "& .MuiTab-root": {
                        margin: `0 ${theme.spacing(1)}`,
                        padding: `${theme.spacing(1)} 0`,
                        textTransform: "none",
                        // [theme.breakpoints.down("lg")]: {
                        //     minHeight: "32px",
                        //     margin: `0 ${theme.spacing(1.5)}`,
                        //     padding: `8px 0`,
                        // },
                        "&:nth-of-type(1)": {
                            marginLeft: 0,
                        },
                        "&.Mui-selected": {
                            fontWeight: FONT_WEIGHT_SEMI_BOLD,
                        },
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    "&.MuiToggleButton-sizeSmall": {
                        fontSize: "13px",
                        padding: "4px",
                    },
                    // Add a right border to create separation between buttons
                    borderRight: `2px solid #fff`,
                    // Remove right border from the last button in a group
                    "&:last-of-type": {
                        borderRight: "none",
                    },
                    // Ensure the border is visible even when selected
                    "&.Mui-selected": {
                        borderRight: `2px solid #fff`,
                    },
                    "&.Mui-selected:last-of-type": {
                        borderRight: "none",
                    },
                    // Override default border styling to eliminate double borders
                    "&:not(:first-of-type)": {
                        borderLeft: "none",
                    },
                    // Keep default border radius handling
                    "&:not(:first-of-type):not(:last-of-type)": {
                        borderRadius: 0,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    background: theme.palette.background.paper,
                    // fontSize: FONT_SIZE_BODY_SMALL,
                    boxShadow: BOX_SHADOW_FLOATING_MEDIUM,
                    borderRadius: BORDER_RADIUS,
                    // padding: theme.spacing(1),
                    color: theme.palette.text.primary,
                    "& .MuiTooltip-arrow": {
                        color: theme.palette.background.paper,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: FONT_SIZE_BODY_MAIN_HEADLINE,
                    fontWeight: FONT_WEIGHT_BOLD,
                    color: theme.palette.text.primary,
                    padding: 0,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                deleteIcon: {
                    fontSize: "14px", // Ensure consistent font size
                },
            },
        },
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        "primary-light": true
    }
}
